
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { BrowserModule, Meta } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, APP_INITIALIZER } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { TranslateService } from './core/translate.service'
import { FormsModule } from '@angular/forms'
import { NotFoundComponent } from './not-found/not-found.component'

import { TranslatePipe } from './shared/pipes/translate.pipe'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatDialogModule } from '@angular/material/dialog'


/***********************************************************************************************
constants
***********************************************************************************************/

// inits the translation factory
export function setupTranslateFactory(
    service: TranslateService): Function {
    return () => service.use('de')
}


/***********************************************************************************************
decorators
***********************************************************************************************/

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        MatSnackBarModule,
        MatDialogModule
    ],
    providers: [
        Meta,
        TranslatePipe,
        TranslateService,
        {
            provide: APP_INITIALIZER,
            useFactory: setupTranslateFactory,
            deps: [TranslateService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})


/***********************************************************************************************
class AppModule
***********************************************************************************************/

export class AppModule { }
