
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

import { GlobalUiService } from 'src/app/core/global-ui.service'
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe'
import { environment } from 'src/environments/environment'
import _q from './q'


/***********************************************************************************************
decorators
***********************************************************************************************/

@Injectable({
    providedIn: 'root'
})


/***********************************************************************************************
class QuestionnaireService
***********************************************************************************************/

export class QuestionnaireService {

    // members
    /*-----------------------------------------------------------------------------------*/

    private _categoryMap: any
    private _categories: any[]
    private _categoryList: any[]
    private _questionnaire: any
    private _currentContentIndex: number
    private _currentPageIndex: number
    private _lastDirectionForward: boolean

    // constructor
    /*-----------------------------------------------------------------------------------*/

    constructor( 
        private _ui: GlobalUiService,
        private _http: HttpClient,
        private _translate: TranslatePipe,
        private _snackBar: MatSnackBar
    ) {
        
        // resets the settings
        this.resetAll()

        // loggs the construction of the class
        _ui.logMessage('service "questionnaire" is constructed', 2)
    }

    // public
    /*-----------------------------------------------------------------------------------*/

    // init
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * resets all values
     */
    resetAll() {
        
        this._categoryMap = {}
        this._categories = []
        this._categoryList = []
        this._currentContentIndex = 0
        this._currentPageIndex = 1
        this._lastDirectionForward = true
        this._questionnaire = null
    }

    
    /**
     * initilization of the serv ices
     * @returns void
     */
    init(): void {
        
        this.resetAll()

        let persistedCategoryMap = JSON.parse(localStorage.getItem(environment.appConfig.localStorageIdentifiers.categoryMap))
        let persistedCategories = JSON.parse(localStorage.getItem(environment.appConfig.localStorageIdentifiers.categories))

        if(persistedCategoryMap) {
            
            this._categoryMap = persistedCategoryMap
            
            this._categories = persistedCategories
        }
        else {

            this._http.get(environment.http.baseUri + environment.http.endpoints.questionnaire).subscribe(
                (val) => {
                    /* (つ◕౪◕)つ━☆ﾟ.*･｡ﾟ */  
                    if(environment.appConfig.loadLocalDebuggingQuestionnaire) val = _q

                    this._categoryMap = this.flattenJson( val )
                    this._categories = this.getCategoryViewsFromJSON( val )
                    this._questionnaire = val
                },
                err => {
                    this._snackBar.open(this._translate.transform('snack_questionnaire_abgleich'), null, {
                        panelClass: ['snack-bar-color-error'],
                        duration: 1500
                    })
                    this._ui.logMessage('ERROR: 401', 6)
                }
            )
        }
    }

    // support
    /*-----------------------------------------------------------------------------------*/

    /**
     * resets everything and re-procures the questionnaire
     */
    cleanUp = () => {
        
        this._categoryMap = {}
        this._categories = []
        this._categoryList = []   
        localStorage.removeItem(environment.appConfig.localStorageIdentifiers.categoryMap)
        localStorage.removeItem(environment.appConfig.localStorageIdentifiers.categories)

        this._http.get(environment.http.baseUri + environment.http.endpoints.questionnaire).subscribe(
            val => {
                this._categoryMap = this.flattenJson( val )
                this._categories = this.getCategoryViewsFromJSON( val )
                this._questionnaire = val
            },
            err => {
                this._categoryMap = this.flattenJson( this._questionnaire )
                this._categories = this.getCategoryViewsFromJSON( this._questionnaire )
            }
        )
    }

    /**
     * gets the second index of the linkId
     * @param  {string} linkId
     * @returns string
     */
    getSecondIndex = (linkId: string): string => {

        function getPosition(index) {
            return linkId.split('.', index).join('.').length
        }

        return linkId.substring(getPosition(1) + 1, getPosition(2))
    }

    /**
     * calculates the indent based on the id
     * @param  {string} id
     * @returns number
     */
    calculateIndent = (id: string): number => {

        let margin = (Math.round(id.split(".").length/2) - 2)
        
		return margin >= 1 ? margin * 15 : 0
    }

    /**
     * checks if the id is in the name of an item
     * @param  {} str
     * @returns string
     */
    checkNameId = (str) : string => {
		return str.split('#')[str.includes('# ') ? 1 : 0].trim()
    }

    // data handling
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * removes an answer from an open-choice answer set
     * @param  {} item
     */
    removeOpenAnswer = (item) => {

		if(item.type !== 'open-choice') return
		let a = this._categoryMap[item.linkId].answerOption.filter(e => e.isOpenQuestionAnswer)[0]
		a.answer = null
	}

    /**
     * saves the q-data to the localstorage
     */
    persistQuestionnaire = () => {
        localStorage.setItem(environment.appConfig.localStorageIdentifiers.categoryMap, JSON.stringify(this._categoryMap))
        localStorage.setItem(environment.appConfig.localStorageIdentifiers.categories, JSON.stringify(this.categories))
    }
    
    /**
     * sets and updates aswers and persists the new state afterwards
     * @param  {} item
     * @param  {} value
     * @param  {} isOpenQuestionAnswer?
     */
    updateAndPersistQuestionnaire = (item, value, isOpenQuestionAnswer?) => {
        
        if(!item.type.includes('choice')) return

        let contained = false
			
        let answers = this._categoryMap[item.linkId].answer

        if(!isOpenQuestionAnswer) {
            
            if(item.repeats) {
                for(let i = answers.length - 1; i >= 0; i--) {
                    if(answers[i] === value) {
                        answers.splice(i, 1)
                        contained = true
                    }
                }
    
                if(!contained) answers.push(value)

            }
            else {
                this._categoryMap[item.linkId].answer = [value]
                this.removeOpenAnswer(item)
            }
    
            localStorage.setItem(environment.appConfig.localStorageIdentifiers.categoryMap, JSON.stringify(this._categoryMap))
            localStorage.setItem(environment.appConfig.localStorageIdentifiers.categories, JSON.stringify(this.categories))
        }

        if(isOpenQuestionAnswer) {
            
            if(item.repeats) {
                for(let i = answers.length - 1; i >= 0; i--) {
                    if(answers[i] === value) {
                        answers.splice(i, 1)
                        contained = true
                    }
                }

                if(value="") answers.filter(e =>  e)
                if(!contained) answers.push(value)
            }
            else {
                this._categoryMap[item.linkId].answer = value === "" ? [] : [value]
            }

            localStorage.setItem(environment.appConfig.localStorageIdentifiers.categoryMap, JSON.stringify(this._categoryMap))
            localStorage.setItem(environment.appConfig.localStorageIdentifiers.categories, JSON.stringify(this.categories))
        }
    }

    /**
     * checks if an answer of an open choice item contains a certain value
     * @param  {} item
     * @param  {} value
     */
    verifyOpenChoiceAnswer = (item, value) => {

        return this._categoryMap[item.linkId].answer && this._categoryMap[item.linkId].answer.includes( value )
    }

    /**
     * sets _currentContentIndex to the next page
     */
    nextCategory = () => {
        
        this._currentContentIndex = this._currentContentIndex === this._categories.length ? 0 : this._currentContentIndex + 1  
    }

    /**
     * sets _currentContentIndex to the previous page
     */
    prevCategory = () => {
        this._currentContentIndex = this._currentContentIndex === 0 ? this._categories.length - 1 : this._currentContentIndex -1 
    }

    /**
     * closes the questionnaire dialog
     * @param  {MatDialogRef<any>} dialogRef
     */
    closeDialog = ( dialogRef: MatDialogRef<any> ) => {
        this._ui.logMessage('close questionnaire', 4)
        dialogRef.close()
    }

    /**
     * browses to the next page of the current cateogry
     * @param  {MatDialogRef<any>} dialogRef
     */
    nextPage = ( dialogRef: MatDialogRef<any> ) => {

        this._ui.logMessage('next page', 4)

        this._lastDirectionForward = true

        if(this._categories[this._currentContentIndex].item.length === this._currentPageIndex) {

            this.closeDialog(dialogRef)

            return
        }
        
        this._currentPageIndex = this._currentPageIndex + 1

        this.checkPageRenderState(dialogRef)

        this.persistQuestionnaire()
        
        setTimeout(() => {
            let input = document.querySelector("input")
            if(input && input.type === 'text') input.focus() 
        }, 0)
    }

     /**
     * browses to the previous page of the current cateogry
     */
    prevPage = () => {

        this._ui.logMessage('previous page', 4)

        this._lastDirectionForward = false

        this._currentPageIndex = this._currentPageIndex === 1 ? this._currentPageIndex : this._currentPageIndex - 1

        this.checkPageRenderState()

        this.persistQuestionnaire()
    }

    // validation
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * checks if the content of the currently displayed modal window is completed
     * @returns boolean
     */
    checkPageState = (): boolean => {
        
		return this.checkCompletionState([
			this._categories[this._currentContentIndex].item[
				this._currentPageIndex - 1
			],
		])
    }

    /**
     * checks if a regular expression of an item checks out
     * @param  {any} element rthe element itself
     * @param  {any} map? a custom categoryMap
     * @returns boolean
     */
    checkRegEx  = (element: any, map?: any ): boolean => {

        if(!map) map = this._categoryMap

        if(!element.extension) element = map[element.linkId]

        if(element.extension && element.extension.length) {
            for(let i = 0; i < element.extension.length; i++) {
                if(element.extension[i].valueString === "/\S+@\S+\.\S+/") {
                    return /\S+@\S+\.\S+/.test(this._categoryMap[element.linkId].answer)
                }
                else if(!RegExp(element.extension[i].valueString).test(this._categoryMap[element.linkId].answer)) {
                    return false
                }
            }
        }

        return true
    }
    
    /**
     * checks the completion state of a category (set of items)
     * @param  {any[]} items?
     * @returns boolean
     */
    checkCompletionState = ( items?: any[] ): boolean => {

        let zhis = this
		let categoryMap = Object.assign({}, { ...this._categoryMap, done: true })
        let categories = items || this._categories
        
        /**
         * used to set the completion state of a single item
         * @param  {string} linkId
         * @param  {boolean} done
         */
        const setDone = ( linkId: string, done: boolean ) => categoryMap[linkId].done = done
		
        /**
         * iterates over a given set of elements and checks their combined completion state
		 * @param  {any[]} elements
		 */
		const traverseElements = ( elements: any[] ) => {
            
            let combinedResult = true

			elements.forEach(function( element: any ) {
                
                if ( element.enableWhen ) {
                    
                    if( !element.enableBehavior || element.enableWhen.length === 0 || ( element.enableBehavior && element.enableBehavior === 'all' )) {
                        element.enableWhen.forEach((condition) => {
                            if((
                                    (Array.isArray(categoryMap[condition.question].answer) && categoryMap[condition.question].answer.includes(condition[zhis.getPropertyName(condition)] ))
                                    ||
								    categoryMap[condition.question].answer === condition[zhis.getPropertyName(condition)]
                                ) 
                                && 
                                !checkElement(element)
							) {
                                combinedResult = false
                            }
						})
					}
					else {
                        
                        let individualResult = false

                        let conditionWasTriggered = false
                        
						element.enableWhen.forEach(( condition: any ) => {

							if(
                                (Array.isArray(categoryMap[condition.question].answer) && categoryMap[condition.question].answer.includes(condition[zhis.getPropertyName(condition)])) 
							    || 
                                categoryMap[condition.question].answer === condition[zhis.getPropertyName(condition)]
                            ) {

                                if(checkElement(element)) individualResult = true

								conditionWasTriggered = true
							}
						})
                        
						if( !conditionWasTriggered ) individualResult = true

                        if(!individualResult) combinedResult = false
					}
				} else {
					if( !checkElement( element ) ) combinedResult = false
				}
			})
			return combinedResult
		}

		/**
         * checks if a given element was answered correctly
		 * @param  {any} element
		 */
		const checkElement = (element: any) => {
            
            let returnValue

            if(element?.text?.includes("!!!")) {
                returnValue = false
            }
			else if (element.type === 'ignore' || !element.required || !this.checkDependencies(element)) {
                
				returnValue = true
            } 
            else if(!this.checkRegEx(element, categoryMap)) {
                returnValue = false
            }
            else {
				if (element.type === 'boolean') {
                    returnValue = element.item ? traverseElements(element.item) : true
                } else if (
                    element.type === 'date' ||
                    element.type === 'string' ||
                    element.type === 'integer' ||
                    element.type === 'number'
                ) {
                    returnValue = categoryMap[element.linkId].answer && categoryMap[element.linkId].answer !== ''
                } else if (!element.item) {
                    returnValue = categoryMap[element.linkId].answer != null
                } else {
                    returnValue = traverseElements(element.item)
                }

                if (returnValue && (element.type === 'choice' || element.type === 'open-choice')) {

                    if(!element.repeats) {
                        returnValue = categoryMap[element.linkId].answer.length > 0 && categoryMap[element.linkId].answer[0] !== "" && categoryMap[element.linkId].answer[0] !== null
                    }
                    else {		
                        let isArray = (Array.isArray(categoryMap[element.linkId].answer) && categoryMap[element.linkId].answer.length )
                        returnValue = isArray
                    }
                }
			}

			setDone(element.linkId, returnValue)

			return returnValue
		}

		/**
         * iterates over a set of items to determine if those wete answered correctly
		 * @param  {any[]} items
		 */
		const checkElements = (items: any[]) => {
			let returnValue = true
			items.forEach((item) => {
				if (!checkElement(item)) returnValue = false
			})

			return returnValue
		}

		if (items) return checkElements(items)
		else {
			categories.forEach((category) => {
				if (!checkElement(category)) categoryMap.done = false
			})
			this._categoryMap = categoryMap
			return categoryMap.done
		}

    }
    
    /**
     * checks if the4 dependencies of a given item check out
     * @param  {any} element
     * @returns boolean
     */
    checkDependencies = ( element: any): boolean => {
        
        let returnValue = false
		let zhis = this

		/**
         * checks if any answers are even available fopr an item
		 * @param  {any} e the item
		 */
		const checkIfAnswersAreAvailable = ( e: any ) => {
            
            if( e.enableBehavior && e.enableBehavior === 'all' ) {
                
                var available = true
                
                e.enableWhen.forEach(( b ) => {
					if (zhis._categoryMap[b.question].answer === undefined || zhis._categoryMap[b.question].answer === null) available = false
				})
                
                return available
			}
			else {
                
                var available = false
                
                e.enableWhen.forEach(( b ) => {
                    if ( (zhis._categoryMap[b.question].answer !== undefined && 
                        zhis._categoryMap[b.question].answer !== null) ||
                        (zhis._categoryMap[b.question].type === 'boolean' && zhis._categoryMap[b.question].answer !== false)
                    ) {
                        available = true
                    }
				})
                
                return available
			}
		}

		if ( element && element.enableWhen ) {

            if (!checkIfAnswersAreAvailable (element )) {
                
                returnValue = false
            }
            else {

                if(!element.enableBehavior || element.enableWhen.length === 0 || (element.enableBehavior && element.enableBehavior === 'all')) {
                    returnValue = true
                    element.enableWhen.forEach((condition) => {
                        if (!(
                            (Array.isArray(zhis._categoryMap[condition.question].answer) &&
                            zhis._categoryMap[condition.question].answer.includes( condition[zhis.getPropertyName(condition)])) ||
                            zhis._categoryMap[condition.question].answer === condition[zhis.getPropertyName(condition)]
                        )) returnValue = false
                    })
                    return returnValue	
                }
                else {
                    element.enableWhen.forEach((condition) => {
                        if (
                            Array.isArray(this._categoryMap[condition.question].answer) && 
                            this._categoryMap[condition.question].answer.includes( condition[zhis.getPropertyName(condition)])
                        ) returnValue = true
                        else if (
                            this._categoryMap[condition.question].answer === condition[zhis.getPropertyName(condition)]
                        ) {
                           
                            returnValue = true
                        }
                    })	
                }
			}
        } 
        else if ( element ) {
            
            returnValue = true
        }
        else {
            
            returnValue = false
        }

		return returnValue
    }
    
    /**
     * checks if there is anything at all to render on the current modal page
     * @param  {MatDialogRef<any>} dialogRef?
     * @returns boolean
     */
    checkPageRenderState = (dialogRef?: MatDialogRef<any>): boolean => {

        let renderState = false

        this._categories[this._currentContentIndex].item.forEach(( item: any ) => {

            if( this.getSecondIndex(item.linkId) === this.currentPageIndex.toString() && this.checkDependencies( item ) ) renderState = true
        })

        if( !renderState ) {
            if( this._lastDirectionForward ) {
                
                this.nextPage(dialogRef)
            }
            else {

                this.prevPage()
            }
        }

        return renderState 
    }

    // export
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * creates the reponse JSON
     * @returns string
     */
    createResponseJSON = (): string => {

        var zhis = this
    
        /**
         * checks the condtions of a given element
         * @param  {any} item
         */
        const checkConditions = (item: any) => {

            let subset = false
            
            if(item.enableWhen) {
                if(!item.enableBehavior || item.enableWhen.length === 0 || (item.enableBehavior && item.enableBehavior === 'all')) {
                    subset = true
                    item.enableWhen.forEach((condition) => {
                        if (!(
                            (Array.isArray(zhis._categoryMap[condition.question].answer) &&
                                zhis._categoryMap[condition.question].answer.includes(
                                    condition[zhis.getPropertyName(condition)]
                                )) ||
                            zhis._categoryMap[condition.question].answer ===
                                condition[zhis.getPropertyName(condition)]
                        ))
                            subset = false
                    })
                    return subset	
                }
                else {
                    subset = false
                    item.enableWhen.forEach((condition) => {
                        if (
                            (Array.isArray(zhis._categoryMap[condition.question].answer) &&
                                zhis._categoryMap[condition.question].answer.includes(
                                    condition[zhis.getPropertyName(condition)]
                                )) ||
                            zhis._categoryMap[condition.question].answer ===
                                condition[zhis.getPropertyName(condition)]
                        )
                            subset = true
                    })
                    return subset	
                }
            }
            else {
                return true
            }
        }

        /**
         * creates the appropriate answer object
         * @param  {} answer
         */
        const createAnswerObject = (answer) => {

            if (typeof answer === "string") return { valueString: answer }
    
            if (typeof answer === "number") return { valueInteger: answer }
    
            // if(typeof answer === "object") return { valueCoding: answer }
            
            return { valueString: answer }
        }
    
        
        /**
         * creates a single item of a response
         * @param  {any} item
         * @param  {any} necessaryAnswer? if true ->tells us that at least one aswer must be given
         */
        const createItems = (item: any, necessaryAnswer?: any) => {
            let newItems = []
    
            if (item)
                item.forEach(function(subItem) {
                    
                    let obj: any = {}
                    var childItems = []
                    var subItemDetails = zhis._categoryMap[subItem.linkId]
    
                    if (
                        checkConditions(subItem) ||
                        (necessaryAnswer &&
                            subItemDetails.enableWhen &&
                            subItemDetails.enableWhen[0][
                                zhis.getPropertyName(subItemDetails.enableWhen[0])
                            ] === necessaryAnswer)
                    ) {
                        let newItem: any = {
                            linkId: subItem.linkId,
                            text: subItem.text,
                            ...(subItemDetails.uuid && {definition: 'urn:uuid:' + subItemDetails.uuid}),
                        }
    
                        switch (subItem.type) {
                            case 'group':
                                newItem.item = createItems(subItem.item)
                                break
    
                            case 'boolean':
                                obj = {
                                    valueBoolean: subItemDetails.answer || false,
                                    item: ''
                                }
                                childItems = subItem.item ? createItems(subItem.item) : []
                                if (childItems.length !== 0) obj.item = childItems
                                newItem.answer = [obj]
                                break
    

                            case 'choice':
                            case 'open-choice':
                                newItem.answer = []
                                // if there are multiple answers
                                if (subItem.repeats) {
                                    // iterates over all answers
                                    subItemDetails.answer.forEach(function (answer) {
                                        if(answer) {
                                            // so now we create an object for each set answer
                                            obj = createAnswerObject(answer)
                                            // and check if there are any child-items.
                                            // if yes: traverse the child-items and add them to the answer
                                            childItems = subItem.item ? createItems(subItem.item, answer): []
                                            if (childItems.length !== 0) obj.item = childItems
                                            newItem.answer.push(obj)  
                                        }
                                    })

                                    // should the type be open-choice and an extra answer is possible
                                    if(subItemDetails.type === 'open-choice') {
                                        let additionalAnswer = subItemDetails.answerOption.filter(e => e.isOpenQuestionAnswer)[0]
                                        if(additionalAnswer.answer) newItem.answer.push(createAnswerObject(additionalAnswer.answer))
                                    }
                                }
                                // if there is just a single answer
                                else {
                                    if(!subItemDetails.answer?.length) break

                                    obj = createAnswerObject(subItemDetails.answer[0])
                                    // traverse the child-items, if there are any and add them to the answer
                                    childItems = subItem.item ? createItems(subItem.item) : []
                                    if (childItems.length !== 0) obj.item = childItems
                                    newItem.answer = [obj]
                                }
                                break

                            case 'string':
                                newItem.answer = [
                                    {
                                        valueString: subItemDetails.answer,
                                    },
                                ]
                                break
    
                            case 'integer':
                                newItem.answer = [
                                    {
                                        valueInteger: parseInt(subItemDetails.answer),
                                    },
                                ]
                                break
    
                            case 'decimal':
                                newItem.answer = [
                                    {
                                        valueDecimal: parseFloat(subItemDetails.answer),
                                    },
                                ]
                                break
    
                            case 'date':
                                let date = new Date(subItemDetails.answer)
                                newItem.answer = date ? [{valueDate: date.toISOString()}] : []  
                        }

                        newItems.push(newItem)
                    }
                })
            return newItems
        }

        let response = createItems(zhis._categories)

        return JSON.stringify(response)
    }

    // getter
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * @returns any
     */
    get categoryList(): any {
        return this._categoryList
    }
    
    /**
     * @returns any
     */
    get categoryMap(): any {
        return this._categoryMap
    }

    /**
     * @returns any
     */
    get categories(): any {
        return this._categories
    }

    /**
     * @returns number
     */
    get currentContentIndex(): number {
        return this._currentContentIndex
    }

    /**
     * @returns number
     */
    get currentPageIndex(): number {
        return this._currentPageIndex
    }

    /**
     * @returns boolean
     */
      get lastDirectionForward(): boolean {
        return this._lastDirectionForward
    }

    // setter
    /*-----------------------------------------------------------------------------------*/

    /**
     * @param  {number} i
     */
    set currentPageIndex( i:number ) {
        this._currentPageIndex = i
    }

    /**
     * @param  {number} i
     */
      set currentContentIndex( i:number ) {
        this._currentContentIndex = i
    }

    /**
     * @param  {boolean} i
     */
    set lastDirectionForward( i:boolean ) {
        this._lastDirectionForward = i
    }

    // private
    /*-----------------------------------------------------------------------------------*/
	
    /**
     * retrieves the correct property name for a given condtion
	 * @param  {any} condition
	 */
	private getPropertyName = (condition: any) : string => {
        return condition.answerString ? 'answerString' : 
            condition.answerDate ? 'answerDate' :
                condition.answerTime ? 'answerTime' :
                    condition.answerCoding ? 'answerCoding' : 
                        condition.answerInteger ? 'answerInteger' :
                            condition.answerDecimal ? 'answerDecimal' :
                                condition.answerBoolean ? 'answerBoolean' :
                                    condition.answerDateTime ? 'answerDateTime' :
                                        'answerQuantity'
    }
    
    /**
     * returns the catzegories from a quesrtionnaire
     * @param  {any} data
     * @returns any[]
     */
    private getCategoryViewsFromJSON = ( data: any ) : any[] => {
        
        let array = []
        data.item.forEach((item) => array.push(item))
        return array
    }

    /**
     * returns the initial default answer based on the type of an item
     * @param  {string} type
     */
    private getBaseAnswer = ( type: string ) => {
        
        switch (type) {
            case "boolean":
                return false
            case "open-choice":
                return []
            case "choice":
                return []
            default:
                return null
        }
    }

    /** creates the categoryMap
     * @param  {any} data
     * @returns any
     */
    private flattenJson = ( data: any ): any => {
       
        let map: any = {}
    
        /**
         * itereates over an item
         * @param  {} item
         */
        const traverseItem = (item) => {

            map[item.linkId] = {
                ...item,
                linkId: item.linkId,
                uuid: item.prefix || null,
                done: false,
                answer: this.getBaseAnswer(item.type),
                started: false,
                text: item.text,
                type: item.type || 'ignore',
                required: item.required || false,
                enableWhen: item.enableWhen,
                enableBehavior: item.enableBehavior,
                extension: item.extension
            }

            if(item.type === 'open-choice' && !map[item.linkId].answerOption.some(e => e.isOpenQuestionAnswer)) {
                map[item.linkId].answerOption.push({isOpenQuestionAnswer:true, answer: null})
            }

            this._categoryList.push(map[item.linkId])

            if (item.item) item.item.forEach((subItem) => traverseItem(subItem))
        }
    
        if (data.item) data.item.forEach((subItem) => traverseItem(subItem))
        
        map.id = data.title
        map.done = false
        map.started = false
    
        Object.values(map).forEach((item: any) => {
            if(item.enableWhen && item.enableWhen.length) {
                item.enableWhen.forEach((e_item) => {
                    if (!map[e_item.question].followedBy) map[e_item.question].followedBy = []
                    map[e_item.question].followedBy.push(item.linkId)
                })
            }
        })
        return map
    }
}
