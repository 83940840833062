export default {
    "resourceType": "Questionnaire",
    "url": "http://hl7.org/fhir/Questionnaire/Fragebogen_Urtikaria-Sprechstunde",
    "identifier": [{
        "use": "official",
        "system": "urn:UMOID:",
        "value": "Fragebogen Urtikaria-Sprechstunde"
    }],
    "version": "2.2",
    "title": "Fragebogen Urtikaria-Sprechstunde",
    "status": "draft",
    "subjectType":[
       "Patient"
    ],
    "date": "2022-05-20",
    "publisher":"IBM",
    "purpose":"Eingangsfragebogen für die Urtikaria (Nesselsucht)-Sprechstunde der Dermatologie der Unimedizin Mainz",
    "item": [
      {
            "linkId": "0",
            "prefix": "83c33c0a-8ea8-437d-bfd5-fcb0798a5d27",
            "text": "Ihre Personalien",
            "type": "group",
            "required": true,
            "item": [
              {
                    "linkId": "0.1",
                    "prefix": "93e3cc4d-ce03-48c7-a0ee-7982db378b87",
                    "text": "Sind Sie gesetlich oder privat versichert?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [{
                            "valueString": "privat"
                        },
                        {
                            "valueString": "gesetzlich"
                        }
                    ],
                    "item": [{
                        "linkId": "0.1.1",
                        "prefix": "395a6ebf-ad85-4c1c-baa9-7459d4da4d2f",
                        "text": "!!! Bitte wenden Sie sich wegen eines Termin an folgende Email-Adresse: privatambulanz-haut@unimedizin-mainz.de oder telefonisch an 06131/177112 !!!",
                        "type": "display",
                        "enableWhen": [{
                            "question": "0.1",
                            "operator": "=",
                            "answerString": "privat"
                        }]
                    }]
                },
                {
                    "linkId": "0.2",
                    "prefix": "urn:uuid:ccd1782d-be4b-434d-b0dc-23f7917db1f7",
                    "text": "Wie dürfen wir Sie anreden?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [{
                            "valueString": "Frau"
                        },
                        {
                            "valueString": "Herr"
                        },
                        {
                            "valueString": "Vorname, Nachname"
                        }
                    ]
                },
                {
                    "linkId": "0.3",
                    "prefix": "2fbf9e44-bdc7-41fb-8e22-a681afcab29e",
                    "text": "Bitte geben Sie Ihren Vornamen ein.",
                    "type": "string",
                    "required": true
                },
                {
                    "linkId": "0.4",
                    "prefix": "01a48a03-2c97-47a6-a80e-73739dec142a",
                    "text": "Bitte geben Sie Ihren Nachnamen an.",
                    "type": "string",
                    "required": true
                },
                {
                    "linkId": "0.5",
                    "prefix": "a80e5b24-bf67-4f2e-b8b6-17275b9d87e0",
                    "text": "Bitte geben Sie Ihr Geburtsdatum an.",
                    "type": "date",
                    "required": true
                },
                {
                    "linkId": "0.6",
                    "prefix": "d688a375-38a7-43f0-bf6f-5b131f3bf1b1",
                    "text": "Bitte geben Sie Ihre Adresse an.",
                    "type": "string",
                    "required": true
                },
                {
                    "linkId": "0.7",
                    "prefix": "ebd0a18b-f9aa-4536-ab6d-cff463c3515f",
                    "text": "Bitte geben Sie ihre Telefonnummer an.",
                    "type": "string",
                    "required": true,
                    "extension": [
                        {
                            "url": "http://hl7.org/fhir/StructureDefinition/regex",
                            "valueString": "^\\+?(?:[0-9]-?){6,14}[0-9]$"
                        }
                    ]
                },
                {
                    "linkId": "0.8",
                    "prefix": "f1f57efe-da64-4e9a-87e7-b3293e3a80be",
                    "text": "Bitte geben Sie ihre E-Mail-Adresse ein.",
                    "type": "string",
                    "required": true,
                    "extension": [
                        {
                            "url": "http://hl7.org/fhir/StructureDefinition/regex",
                            "valueString": "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$"
                        }
                    ]
                },
                {
                    "linkId": "0.9",
                    "prefix": "c9aba500-9047-494f-b682-8e41f354cacd",
                    "text": "Bitte geben Sie ihre Krankenkasse an.",
                    "type": "string",
                    "required": true
                },
                {
                    "linkId": "0.10",
                    "prefix": "b6cb3eb1-59fe-4565-8df8-785f5ee16f19",
                    "text": "Besteht Ihrerseits Interesse an Forschungsstudien teilzunehmen?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Ja"
                        },
                        {
                            "valueString": "Nein"
                        }
                    ]
                }
            ]
        },
        {
            "linkId": "1",
            "prefix": "3d431ae3-ade5-448f-bb7a-dc864bdf8608",
            "text": "Symptome",
            "type": "group",
            "required": true,
            "item": [
                {
                    "linkId": "1.1",
                    "prefix": "0ac6ad58-4a54-4459-8a35-83562ca6e68e",
                    "text": "Haben sie akut gravierende Symptome, die einer sofortigen Behandlung bedürfen?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Nein"
                        },
                        {
                            "valueString": "Ja"
                        }
                    ],
                    "item": [
                        {
                            "linkId": "1.1.1",
                            "prefix": "442144fd-b3f2-4621-8ccc-6fec4e37aff8",
                            "text": "!!! Bitte suchen Sie umgehend eine Notaufnahme auf oder kontaktieren Sie die 116117 !!!",
                            "type": "display",
                            "enableWhen": [{
                                "question": "1.1",
                                "operator": "=",
                                "answerString": "Ja"
                            }]
                        }
                    ]
                },
                {
                    "linkId": "1.2",
                    "prefix": "a4e92c47-6516-4fe3-bdde-2e4a859c8c92",
                    "text": "Wie stark ist Ihr Juckreiz?",
                    "type": "choice",
                    "answerOption": [
                        {
                            "valueString": "Leicht"
                        },
                        {
                            "valueString": "Mittel"
                        },
                        {
                            "valueString": "Stark"
                        }
                    ],
                    "required": true
                },
                {
                    "linkId": "1.3",
                    "prefix": "0b535e65-1c23-4e85-bb05-5db18d9c8635",
                    "text": "Leiden Sie neben Juckreiz unter immer wiederkehrenden Quaddeln (wie bei Kontakt mit Brennesseln) und/oder Schwellungen im Gesicht Mund/Rachen/Zunge/Lippen und oder Hände/Füße?",
                    "type": "choice",
                    "answerOption": [
                        {
                            "valueString": "Nein"
                        },
                        {
                            "valueString": "Ja"
                        }
                    ],
                    "required": true,
                    "item": [
                        {
                            "linkId": "1.3.1",
                            "prefix": "dd836846-6d12-45fc-9702-356b4765b557",
                            "text": "!!! Bitte wenden Sie sich an die Poliklinik. Sie erreichen die Poliklinik Mo. – Fr. 8:00 bis 12:00 Uhr unter Tel.: 06131 17–2903 !!!",
                            "type": "display",
                            "enableWhen": [{
                                "question": "1.3",
                                "operator": "=",
                                "answerString": "Nein"
                            }]
                        }
                    ]
                },
                {
                    "linkId": "1.4",
                    "prefix": "6d2b4a2c-eb9c-4238-8c27-d68f0b60da64",
                    "text": "Unter welchen der im Folgenden genannten Symptome leiden Sie? Bitte wählen Sie alle Symptome, die bei Ihnen zutreffen.",
                    "type": "open-choice",
                    "repeats": true,
                    "answerOption": [
                        {
                            "valueString": "Quaddeln (wie Brennnesseln)"
                        },
                        {
                            "valueString": "Schwellungen im Gesichtsbereich und oder Mund"
                        },
                        {
                            "valueString": "Schwellungen in anderen Bereichen"
                        },
                        {
                            "valueString": "Engegefühl im Hals / Kloßgefühl im Hals"
                        },
                        {
                            "valueString": "Schwindel / Kreislaufprobleme im Zusammenhang mit den Hautveränderungen"
                        },
                        {
                            "valueString": "Magen-Darm-Beschwerden"
                        },
                        {
                            "valueString": "Gelenkbeschwerden"
                        },
                        {
                            "valueString": "Fieber"
                        },
                        {
                            "valueString": "Allgemeinzustandsminderung (=Verschlechterung des Allgemeinzustandes)"
                        },
                        {
                            "valueString": "Luftnot/Kloßgefühl im Hals"
                        },
                        {
                            "valueString": "Kreislaufprobleme/Schwarz vor Augen"
                        }
                    ],
                    "required": true
                },
                {
                    "linkId": "1.5",
                    "prefix": "e5a6a826-0b78-407f-b23b-3902d1869ecb",
                    "text": "Wann sind in Ihrem Leben erstmalige die Symptome aufgetreten?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "weniger als 6 Wochen"
                        },
                        {
                            "valueString": "mindestens 6 Wochen und mehr "
                        },
                        {
                            "valueString": "halbes Jahr"
                        },
                        {
                            "valueString": "seit einem Jahr"
                        },
                        {
                            "valueString": "mehrere Jahre"
                        }
                    ],
                    "item": [
                        {
                            "linkId": "1.5.1",
                            "prefix": "e60cd9db-da03-4e49-b166-87ca8546f425",
                            "text": "!!! Bitte wenden Sie sich direkt an die Poliklinik oder an den Hautart. Sie erreichen die Poliklinik Mo. – Fr. 8:00 bis 12:00 Uhr unter Tel.: 06131 17–2903 !!!",
                            "type": "display",
                            "enableWhen": [{
                                "question": "1.5",
                                "operator": "=",
                                "answerString": "weniger als 6 Wochen"
                            }]
                        }
                    ]
                },
                {
                    "linkId": "1.6",
                    "prefix": "7fc0be3c-7845-4543-b3e4-f772b3afe7e9",
                    "text": "Wann traten die Symptome ca. zuletzt auf?",
                    "type": "date",
                    "required": true
                }
            ]
        },
        {
            "linkId": "2",
            "prefix": "603449f3-383e-4d37-a249-927b521ec612",
            "text": "Schwellungen",
            "type": "group",
            "required": true,
            "enableBehavior": "any",
            "enableWhen": [
                {
                    "question": "1.4",
                    "operator": "=",
                    "answerString": "Schwellungen im Gesichtsbereich und oder Mund"
                },
                {
                    "question": "1.4",
                    "operator": "=",
                    "answerString": "Schwellungen in anderen Bereichen"
                }
            ],
            "item": [
                {
                    "linkId": "2.1",
                    "prefix": "0941fc21-fafd-47f7-9280-7816dfd0d638",
                    "text": "Wenn Sie Symptome (Schwellungen) haben, wie häufig sind diese?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Täglich"
                        },
                        {
                            "valueString": "Wöchentlich"
                        },
                        {
                            "valueString": "monatlich"
                        },
                        {
                            "valueString": "mehrmals im Jahr"
                        }
                    ]
                },
                {
                    "linkId": "2.2",
                    "prefix": "d812cdb4-3a10-4dbb-8812-69165abf2dbe",
                    "text": "Wenn eine einzelne Hautveränderung (oder Lippen- Augen- Zungenschwellung) betrachtet wird, wie lange bleibt eine bestehen?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Weniger als 48h"
                        },
                        {
                            "valueString": "mehr als 48h"
                        }
                    ]
                },
                {
                    "linkId": "2.3",
                    "prefix": "3d4c4c86-fe0b-4a92-8241-5b5e38ad37a2",
                    "text": "Können Sie einen Grund benennen, warum bei Ihnen Schwellungen auftreten?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Nein, ich kann den Grund nicht benennen"
                        },
                        {
                            "valueString": "Ja"
                        }
                    ]
                },
                {
                    "linkId": "2.4",
                    "prefix": "18884f22-3a2b-4e6c-bef0-f58d07a9a7b9",
                    "text": "Was denken Sie, ist der Grund für das Auftreten von Schwellungen (und Juckreiz)?",
                    "type": "open-choice",
                    "repeats": true,
                    "required": true,
                    "enableWhen": [
                        {
                            "question": "2.3",
                            "operator": "=",
                            "answerString": "Ja"
                        }
                    ],
                    "answerOption": [
                        {
                            "valueString": "Mechanischer Druck"
                        },
                        {
                            "valueString": "Emotionale Belastung (Stress)"
                        },
                        {
                            "valueString": "Körperliche Belastung (Sauna, Sport)"
                        },
                        {
                            "valueString": "Kratzen"
                        },
                        {
                            "valueString": "Wärme"
                        },
                        {
                            "valueString": "Kälte"
                        },
                        {
                            "valueString": "Infekte"
                        },
                        {
                            "valueString": "Medikamenteneinnahme"
                        },
                        {
                            "valueString": "Nahrungsmittel"
                        }
                    ]
                }
            ]
        },
        {
            "linkId": "3",
            "prefix": "6817b16a-1e67-4717-8e27-3c42f84f8d80",
            "text": "Quaddeln",
            "type": "group",
            "required": true,
            "enableWhen": [{
                "question": "1.4",
                "operator": "=",
                "answerString": "Quaddeln (wie Brennnesseln)"
            }],
            "item": [
                {
                    "linkId": "3.1",
                    "prefix": "793a9c4d-3b98-4521-9450-62cc3e0c5cd7",
                    "text": "Wenn Sie Symptome (Quaddeln) haben, wie häufig sind diese?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Täglich"
                        },
                        {
                            "valueString": "Wöchentlich"
                        },
                        {
                            "valueString": "monatlich"
                        },
                        {
                            "valueString": "mehrmals im Jahr"
                        }
                    ]
                },
                {
                    "linkId": "3.2",
                    "prefix": "92505e43-3a62-4929-804d-8591098a9a7c",
                    "text": "Da Sie angaben, in der letzten Woche täglich Symptome gehabt zu haben, bitte geben Sie uns einen Überblick wie ausgeprägt die Symptome im Durchschnitt waren (=Anzahl der Quaddeln).",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "<20 Quaddeln"
                        },
                        {
                            "valueString": "20-50 Quaddeln"
                        },
                        {
                            "valueString": ">50 Quaddeln"
                        }
                    ],
                    "enableWhen": [{
                        "question": "3.1",
                        "operator": "=",
                        "answerString": "Täglich"
                    }]
                },
                {
                    "linkId": "3.3",
                    "prefix": "37201e1b-3d34-4b9b-801a-dc6907346c20",
                    "text": "Treten Ihre Quaddeln spontan/plötzlich auf und können Sie ggf. Gründe dafür erkennen?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Nein, treten nicht plötzlich/spontan auf."
                        },
                        {
                            "valueString": "Ja, treten plötzlich spontan auf, aber ich kann den Grund NICHT benennen."
                        },
                        {
                            "valueString": "Ja, treten plötzlich und spontan UND ich kann den Grund erkennen."
                        }
                    ]
                },
                {
                    "linkId": "3.4",
                    "prefix": "aff4c6dc-0fdb-4c2b-a414-5d6733cea9b1",
                    "text": "Was denken Sie, ist der Grund für das Auftreten von Quaddeln/Juckreiz?",
                    "type": "open-choice",
                    "repeats": true,
                    "required": true,
                    "enableBehavior": "any",
                    "enableWhen": [
                        {
                            "question": "3.3",
                            "operator": "=",
                            "answerString": "Nein, treten nicht plötzlich/spontan auf."
                        },
                        {
                            "question": "3.3",
                            "operator": "=",
                            "answerString": "Ja, treten plötzlich und spontan UND ich kann den Grund erkennen."
                        }
                    ],
                    "answerOption": [
                        {
                            "valueString": "Mechanischer Druck"
                        },
                        {
                            "valueString": "Emotionale Belastung (Stress)"
                        },
                        {
                            "valueString": "Körperliche Belastung (Sauna, Sport)"
                        },
                        {
                            "valueString": "Kratzen"
                        },
                        {
                            "valueString": "Wärme"
                        },
                        {
                            "valueString": "Kälte"
                        },
                        {
                            "valueString": "Infekte"
                        },
                        {
                            "valueString": "Medikamenteneinnahme"
                        },
                        {
                            "valueString": "Nahrungsmittel"
                        }
                    ]
                }
            ]
        },
        {
            "linkId": "4",
            "prefix": "5031f66b-c9a1-45e4-9c89-8344185ba7ac",
            "text": "Magen-Darm-Beschwerden",
            "type": "group",
            "required": true,
            "enableWhen": [{
                "question": "1.4",
                "operator": "=",
                "answerString": "Magen-Darm-Beschwerden"
            }],
            "item": [
                {
                    "linkId": "4.1",
                    "prefix": "8e544373-d018-4f8a-907e-6c3a36422940",
                    "text": "Wenn Sie Symptome (Magen-Darm-Beschwerden) haben, wie häufig sind diese?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Täglich"
                        },
                        {
                            "valueString": "Wöchentlich"
                        },
                        {
                            "valueString": "monatlich"
                        },
                        {
                            "valueString": "mehrmals im Jahr"
                        }
                    ]
                },
                {
                    "linkId": "4.2",
                    "prefix": "41712745-639d-42b8-a6cc-8b1999ef55fb",
                    "text": "Wenn eine einzelne Veränderung, wie Magenkrämpfe betrachtet wird, wie lange bleibt eine bestehen?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Weniger als 48h"
                        },
                        {
                            "valueString": "mehr als 48h"
                        }
                    ]
                },
                {
                    "linkId": "4.3",
                    "prefix": "5828f2b7-e1c0-42a9-a75f-e33945bd37ec",
                    "text": "Können Sie einen Grund benennen, warum bei Ihnen Magen-Darm-Beschwerden auftreten?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Nein, ich kann den Grund nicht benennen"
                        },
                        {
                            "valueString": "Ja"
                        }
                    ]
                },
                {
                    "linkId": "4.4",
                    "prefix": "86c1742e-f40d-436c-8bd0-f49e617cb9c1",
                    "text": "Was denken Sie, ist der Grund für das Auftreten von Magen-Darm-Beschwerden?",
                    "type": "open-choice",
                    "repeats": true,
                    "required": true,
                    "enableWhen": [
                        {
                            "question": "4.3",
                            "operator": "=",
                            "answerString": "Ja"
                        }
                    ],
                    "answerOption": [
                        {
                            "valueString": "Mechanischer Druck"
                        },
                        {
                            "valueString": "Emotionale Belastung (Stress)"
                        },
                        {
                            "valueString": "Körperliche Belastung (Sauna, Sport)"
                        },
                        {
                            "valueString": "Kratzen"
                        },
                        {
                            "valueString": "Wärme"
                        },
                        {
                            "valueString": "Kälte"
                        },
                        {
                            "valueString": "Infekte"
                        },
                        {
                            "valueString": "Medikamenteneinnahme"
                        },
                        {
                            "valueString": "Nahrungsmittel"
                        }
                    ]
                }
            ]
        },
        {
            "linkId": "5",
            "prefix": "6987dfbf-c39a-4084-a93c-6b25229ff146",
            "text": "Voruntersuchungen",
            "type": "group",
            "required": true,
            "item": [
                {
                    "linkId": "5.1",
                    "prefix": "b01e1a2e-a251-44bd-b093-528561de095b",
                    "text": "Waren Sie schon bei einem Facharzt für Hauterkrankungen wegen Ihrer Urtikaria (Nesselsucht)?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Nein"
                        },
                        {
                            "valueString": "Ja"
                        }
                    ]
                },
                {
                    "linkId": "5.2",
                    "prefix": "8586256b-6b42-43ef-a5b8-ea15fef6e376",
                    "text": "Haben Sie sich schon einmal bei uns in der Hautklinik wegen Ihrer Erkrankung vorgestellt? (Poliklinik, Urtikaria (Nesselsucht)-Spezialsprechstunde, Allergie-Spezialsprechstunde etc.)",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Nein"
                        },
                        {
                            "valueString": "Ja"
                        }
                    ],
                    "enableWhen": [{
                        "question": "5.1",
                        "operator": "=",
                        "answerString": "Ja"
                    }]
                },
                {
                    "linkId": "5.3",
                    "prefix": "9ae1d532-419c-4954-a394-bec0eeec20ac",
                    "text": "Welches Ergebnis wurden bei den Untersuchungen bisher erzielt?",
                    "type": "open-choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "keine"
                        }
                    ],
                    "enableWhen": [{
                        "question": "5.1",
                        "operator": "=",
                        "answerString": "Ja"
                    }]
                },
                {
                    "linkId": "5.4",
                    "prefix": "f4c7ea1a-e4be-4cb7-a7b2-bb83b1784ae5",
                    "text": "Wurden schon Untersuchungen durchgeführt um den Grund ihrer Urtikaria (Nesselsucht) zu finden?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Nein"
                        },
                        {
                            "valueString": "Ja"
                        }
                    ],
                    "enableWhen": [{
                        "question": "5.1",
                        "operator": "=",
                        "answerString": "Ja"
                    }]
                },
                {
                    "linkId": "5.5",
                    "prefix": "98ccd738-1670-4059-9fe6-e1b0fe416e22",
                    "text": " Welche Untersuchung wurde zuletzt bezüglich Ihrer Urtikaria (Nesselsucht) durchgeführt? Bitte bringen Sie alle relevanten Unterlagen mit!",
                    "type": "open-choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Untersuchung vom HNO"
                        },
                        {
                            "valueString": "Untersuchungen vom Zahnarzt"
                        },
                        {
                            "valueString": "Untersuchungen vom Gynäkologen"
                        },
                        {
                            "valueString": "Untersuchungen vom Gastroenterologen"
                        },
                        {
                            "valueString": "Untersuchungen vom Endokrinologen (Hormonarzt)"
                        },
                        {
                            "valueString": "Magen-Darm-Spiegelung"
                        },
                        {
                            "valueString": "Schilddrüsendiagnostik"
                        }
                    ],
                    "enableWhen": [
                        {
                            "question": "5.1",
                            "operator": "=",
                            "answerString": "Ja"
                        },
                        {
                            "question": "5.4",
                            "operator": "=",
                            "answerString": "Ja"
                        }
                    ]
                },
                {
                    "linkId": "5.6",
                    "prefix": "a3a9ff7c-19bb-41f7-a199-5b772af3b4cf",
                    "text": "Geben Sie bitte Name sowie Adresse des Arztes an.",
                    "type": "string",
                    "required": true,
                    "enableWhen": [
                        {
                            "question": "5.1",
                            "operator": "=",
                            "answerString": "Ja"
                        },
                        {
                            "question": "5.4",
                            "operator": "=",
                            "answerString": "Ja"
                        }
                    ]
                },
                {
                    "linkId": "5.7",
                    "prefix": "93ccb2fe-bc3c-4794-96eb-86829c2d7cce",
                    "text": "Welches Ergebnis wurden bei den Untersuchungen bisher erzielt?",
                    "type": "open-choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "keine"
                        }
                    ],
                    "enableWhen": [
                        {
                            "question": "5.1",
                            "operator": "=",
                            "answerString": "Ja"
                        },
                        {
                            "question": "5.4",
                            "operator": "=",
                            "answerString": "Ja"
                        }
                    ]
                }
            ]
        },
        {
            "linkId": "6",
            "prefix": "e0bacdd0-0288-455c-9d98-1e4d417da976",
            "text": "Medikation",
            "type": "group",
            "required": true,
            "item": [
                {
                    "linkId": "6.1",
                    "prefix": "72e660c4-ac9c-4f03-b551-ccc2a3dbf925",
                    "text": "Nehmen Sie aktuell Medikamente gegen Ihre Urtikaria (Nesselsucht) ein?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Nein"
                        },
                        {
                            "valueString": "Ja"
                        }
                    ]
                },
                {
                    "linkId": "6.2",
                    "prefix": "2ac1ac36-5bf8-4717-b32e-3f1eaa8e071d",
                    "text": "Welche Medikamente nehmen Sie aktuell gegen Ihre Urtikaria (Nesselsucht) ein?",
                    "type": "open-choice",
                    "repeats": true,
                    "required": true,
                    "enableWhen": [{
                        "question": "6.1",
                        "operator": "=",
                        "answerString": "Ja"
                    }],
                    "answerOption": [
                        {
                            "valueString": "Antiallergika"
                        },
                        {
                            "valueString": "Kortison"
                        },
                        {
                            "valueString": "Xolair® Omalizumab"
                        }
                    ]
                },
                {
                    "linkId": "6.3",
                    "prefix": "27b60167-4180-4713-9ce7-571364c9f92e",
                    "text": "Haben die Medikamente, die Sie aktuell für Ihre Urtikaria (Nesselsucht) einnehmen, eine Linderung Ihrer Beschwerden erbracht? (z.B. weniger Juckreiz, weniger Quaddeln, weniger Schwellungen)?",
                    "type": "choice",
                    "required": true,
                    "enableWhen": [{
                        "question": "6.1",
                        "operator": "=",
                        "answerString": "Ja"
                    }],
                    "answerOption": [
                        {
                            "valueString": "Ja"
                        },
                        {
                            "valueString": "Nein"
                        }
                    ]
                },
                {
                    "linkId": "6.4",
                    "prefix": "16ff14e0-f4af-4d85-b3d9-8583cc8ffb5c",
                    "text": "Bitte Kreuzen Sie an, ob Sie folgenden Medikamente (schauen Sie nach dem Wirkstoff auf der Medikamentenverpackung) einnehmen? Endet der Wirkstoff auf …pril oder … sartan?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Ja"
                        },
                        {
                            "valueString": "Nein"
                        }
                    ]
                },
                {
                    "linkId": "6.5",
                    "prefix": "bcf7af45-1632-4cfe-a9b8-bc2f999df2d3",
                    "text": "Welche anderen Erkrankungen sind bei Ihnen bekannt?",
                    "type": "open-choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Keine"
                        }
                    ]
                },
                {
                    "linkId": "6.6",
                    "prefix": "5ef87f95-b259-45e9-b38b-a8e1fafd6131",
                    "text": "Nehmen Sie für eventuell vorliegende sonstige Erkrankungen Medikamente ein?",
                    "type": "open-choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Keine"
                        }
                    ]
                },
                {
                    "linkId": "6.7",
                    "prefix": "715648b6-bd4d-4aff-8952-a4bcd00e3d5a",
                    "text": "Welche Schmerzmedikamente nehmen Sie bei Schmerzzuständen ein?",
                    "type": "open-choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Keine"
                        }
                    ]
                }
            ]
        },
        {
            "linkId": "7",
            "prefix": "537f3a97-133f-42df-807d-063a5a0dccaa",
            "text": "Kortison",
            "type": "group",
            "required": true,
            "enableWhen": [{
                "question": "6.2",
                "operator": "=",
                "answerString": "Kortison"
            }],
            "item": [
                {
                    "linkId": "7.1",
                    "prefix": "92eefdb5-adb4-4da9-b983-ae1c458a8ae3",
                    "text": "Nehmen Sie Kortison über 50mg oder unter 50mg ein?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Kortison über 50mg"
                        },
                        {
                            "valueString": "Kortison unter 50mg"
                        }
                    ]
                },
                {
                    "linkId": "7.2",
                    "prefix": "12c89af2-5319-48cb-a46e-4947f7962653",
                    "text": "Konnten die Symptome schnell (wenige Stunden) mit Kortison behoben werden?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Nein"
                        },
                        {
                            "valueString": "Ja"
                        }
                    ]
                },
                {
                    "linkId": "7.3",
                    "prefix": "88837999-6ac9-45be-8a2c-3e4fc661588d",
                    "text": "Wie oft nehmen Sie Kortison auf Grund und zur Linderung der oben genannten Symptome ein?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "täglich"
                        },
                        {
                            "valueString": "mehrmals pro Woche"
                        },
                        {
                            "valueString": "bei Bedarf"
                        }
                    ]
                },
                {
                    "linkId": "7.4",
                    "prefix": "0be765c3-83a7-4944-a83e-f4c04149120e",
                    "text": "Seit wann nehmen Sie Kortison ein?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "weniger als 6 Monate"
                        },
                        {
                            "valueString": "halbes Jahr"
                        },
                        {
                            "valueString": "ganzes Jahr"
                        },
                        {
                            "valueString": "länger als 5 Jahre"
                        }
                    ]
                }
            ]
        },
        {
            "linkId": "8",
            "prefix": "2f0593d6-a780-4818-8cef-0e5c6049275f",
            "text": "Antiallergika",
            "type": "group",
            "required": true,
            "enableWhen": [{
                "question": "6.2",
                "operator": "=",
                "answerString": "Antiallergika"
            }],
            "item": [
                {
                    "linkId": "8.1",
                    "prefix": "3e4200bb-521e-4776-a4c8-4331f9b440d6",
                    "text": "Welche Antiallergika nehmen Sie ein?",
                    "type": "open-choice",
                    "repeats": true,
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Cetirizin"
                        },
                        {
                            "valueString": "Lorano"
                        },
                        {
                            "valueString": "Loratadin"
                        },
                        {
                            "valueString": "Aerius"
                        }
                    ]
                },
                {
                    "linkId": "8.2",
                    "prefix": "145fdb04-5068-45c4-aa8b-970d2f26f24a",
                    "text": "Wie häufig nehmen Sie die Antiallergika täglich ein?",
                    "type": "choice",
                    "required": true,
                    "answerOption": [
                        {
                            "valueString": "Bei Bedarf"
                        },
                        {
                            "valueString": "1x"
                        },
                        {
                            "valueString": "2x"
                        },
                        {
                            "valueString": "3x"
                        },
                        {
                            "valueString": "4x"
                        },
                        {
                            "valueString": "Häufiger als 4x"
                        }
                    ]
                }
            ]
        }
    ]
}