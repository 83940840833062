
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

// this file provides methods that can be used throughout the application.

/***********************************************************************************************
imports
***********************************************************************************************/

import { Injectable } from '@angular/core'
import * as saveSvgAsPng from 'save-svg-as-png'
import { TranslateService } from './translate.service'


/***********************************************************************************************
constants
***********************************************************************************************/

// the braking point to determine if we are on a small or a wirde screen
const SMALL_WIDTH_BREAKPOINT = 599

// options needed by saveSvgAsPng
const imageOptions = {
    scale: 5,
    encoderOptions: 1,
    backgroundColor: 'white',
}


/***********************************************************************************************
decorators
***********************************************************************************************/

// providedIn: 'root' specifies that Angular should provide the service in the root injector
@Injectable({
    providedIn: 'root'
})


/***********************************************************************************************
class GlobalUiService
***********************************************************************************************/

export class GlobalUiService {

    // members
    /*-----------------------------------------------------------------------------------*/

    private _logOutputs: boolean
    private _mediaMatcher: MediaQueryList
    private _logComponents: boolean
    private _logConstructions: boolean

    // constructor
    /*-----------------------------------------------------------------------------------*/

    constructor(
        private _translate: TranslateService
    ) {
        this._init()
    }

    // private
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * module initilization
     * @returns void
     */
    private _init(): void {

        // options to determine what needs to be logged into the console
        this._logOutputs = false
        this._logComponents = false
        this._logConstructions = false

        // is being updated when a resize occures
        this._mediaMatcher = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`)

        // loggs the construction of the class
        this.logMessage('service "global-ui" is constructed', 2)
    }

    // public
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * tells us if this is running opn a small screen or not
     * @returns boolean
     */
    isScreenSmall(): boolean {

        return this._mediaMatcher.matches
    }

    /**
     * @param  {string} lang the language string -> should be identical to the filenames of the löanguage file (i.e "de")
     */
    setLang(lang: string) {

        this._translate.use(lang)
    }

    /**
     * function to set the languge value
     */
    switchLang() {

        var nextLang = ''

        switch (this._translate.current) {
            case "de":
                nextLang = 'en'
                break
        
            default:
                nextLang = 'de'
        }

        this.setLang(nextLang)
    }

    /**
     * @param  {string} id id of the appointment, used as fiulenmae
     */
    downloadQrCode(id: string) {
        
        saveSvgAsPng.saveSvgAsPng(document.getElementsByTagName("svg")[0], id + '.png', imageOptions)
    }

    /**
     * used to output a message in the console
     * @param  {string} message the string to be displayed
     * @param  {number} indent indentation of the string
     * @returns void
     */
    logMessage(message: string, indent: number): void {
        
        if (this._logOutputs) {
            if (message.indexOf('component') > -1 && !this._logComponents) return
            if (message.indexOf('constructed') > -1 && !this._logConstructions) return

            let prefix: string = ''
            for (let i = 0; i < indent; i++) prefix += '-'
            prefix += '>'

            message = message.toUpperCase()

            console.log(`${prefix} ${message}`)
        }
    }

    /**
     * creates the correct date string from a DateTime
     * @param  {string} inputDate datestring
     * @returns string
     */
    formatDateString(inputDate: string): string {

        let date= new Date(inputDate)
        
        let day = date.getDate()
        let month = date.getMonth()
        let hour = date.getHours()
        let minutes = date. getMinutes()
        
        month++

        let _day = day.toString()
        let _month = month.toString()
        let _hour = hour.toString()
        let _minutes = minutes.toString()
        
        if((_day).length==1) _day = '0' + _day
        if((_month).length==1) _month = '0' + _month
        if((_hour).length==1) _hour = '0' + _hour
        if((_minutes).length==1) _minutes = '0' + _minutes

        let dateString = _day + '.' + _month + '.' + date.getFullYear()
        dateString = dateString + ', ' + inputDate.substring(11, 16)
        
        return dateString
    }
}
