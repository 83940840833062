
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { Component } from '@angular/core'
import { QuestionnaireService } from './main-app/services/questionnaire.service'


/***********************************************************************************************
decorators
***********************************************************************************************/

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})


/***********************************************************************************************
class AppComponent
***********************************************************************************************/

export class AppComponent {
	
	// title of the app
	title = 'Urtikaria-Sprechstunde'

	// constructor
    /*-----------------------------------------------------------------------------------*/

	constructor( private _qService: QuestionnaireService ) {
		
		window.addEventListener('beforeinstallprompt', (event) => {})
	}

	 // public
    /*-----------------------------------------------------------------------------------*/
	
	/**
	 * initilization event
	 */
	ngOnInit() {

		// initializes the questionnaire service
		this._qService.init()
	}
}
