
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { Component } from '@angular/core'
import { GlobalUiService } from '../core/global-ui.service'


/***********************************************************************************************
decorators
***********************************************************************************************/

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})


/***********************************************************************************************
class NotFoundComponent
***********************************************************************************************/

export class NotFoundComponent {

    constructor(
        public ui: GlobalUiService,
    ) {

        // loggs the construction of the class
        ui.logMessage('component "not-found" is constructed', 0)
    }
}
