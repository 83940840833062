// This file will be used during build for the production system in the cluster

export const environment = {
    
    // should this produce a production build?
	production: true,

    // is this build used in a staging environment?
	staging: true,

    // http endoints
	http: {
        // the base-uri of the backend (most of the tinme just '/api')
		baseUri: '/api',

        // the api-endpints
		endpoints: {
			questionnaire: '/questionnaire',
			appointment: '/appointment ',
			availableSlot: '/appointment/availableSlots?date=',
			scheduleConfig: '/schedule/config',
			scanSuccess: '/appointment/scan-success'
		}
	},

	appConfig: {
		appStrings: {
            // if a qr-code reader is used, this is the app-id value the code must hold in order do be readable
			qrAppID: 'DERMA'
		},
		localDateFormat: 'de-DE',

        // for debugging purposes: always loads the local debugging questionnaire ´q.js´
		loadLocalDebuggingQuestionnaire: false,

        // identifiers used by the local storage
		localStorageIdentifiers: {
			appUser: 'derma_app_user',
			categoryMap: 'derma_app_category_map',
			categories: 'derma_app_categories'
		}
	}
}
